export default [{
  numberIndex: '29',
  title: '朱世伟',
  en: 'ZHU',
  path: 'Technical',
  type: '2',
  position: '执业医师',
  text: [
    '碧莲盛太原分院技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '参与碧莲盛毛发康养体系的建设',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '碧莲盛太原分院技术院长，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与碧莲盛毛发康养体系的建设，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。',
  number: ['110141000003915', '201414110142608198212125819']
},
{
  numberIndex: '6',
  en: 'CAO',
  path: 'Technical',
  type: '2',
  title: '曹国荣',
  position: '主治医师',
  text: [
    '碧莲盛长沙院部技术院长',
    '碧莲盛名医堂核心成员',
    '碧莲盛医疗技术带头人',
    '碧莲盛植发技术培训中心特聘带教官',
    '中国整形美容协会毛发医学分会委员',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '主治医师，碧莲盛长沙院部技术院长，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，中国整形美容协会毛发医学分会委员，多次受邀参加国内毛发移植技术交流论，擅长大面积植发、艺术种植和不剃发植发。',
  number: ['110430105000924', '201143110432321197901316777']
},
{
  numberIndex: '7',
  title: '黄广程',
  en: 'HUANG',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '碧莲盛名医堂核心成员',
    '碧莲盛医疗技术带头人',
    '碧莲盛植发技术培训中心特聘带教官',
    '参与不剃发植发技术的研发',
    '参与精密即插即种植发技术研发',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '主治医师，碧莲盛广州院部技术院长，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官。曾参与不剃发植发技术的研发，参与精密即插即种植发技术研发，拥有多年毛发移植临床经验，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。',
  number: ['110440304002476', '20144311043078119840525001X']
},
{
  numberIndex: '8',
  title: '杨昕',
  en: 'YANG',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '碧莲盛杭州院部技术院长',
    '碧莲盛名医堂核心成员',
    '碧莲盛医疗技术带头人',
    '碧莲盛植发技术培训中心特聘带教官',
    '主持精密即插即种植发器械的升级改进',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '主治医师，碧莲盛杭州院部技术院长，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，主持精密即插即种植发器械的升级改进，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长大面积植发、艺术种植和不剃发植发。',
  number: ['1103301060002557', '201033110360302198508230534']
},
{
  numberIndex: '9',
  en: 'XIA',
  path: 'Technical',
  type: '2',
  title: '夏正法',
  position: '主治医师',
  text: [
    '碧莲盛南昌院部技术院长',
    '碧莲盛名医堂核心成员',
    '碧莲盛植发技术培训中心特聘带教官',
    '参与不剃发植发技术的研发',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '主治医师，毕业于武汉科技大学临床医学专业，碧莲盛南昌院部技术院长，碧莲盛名医堂核心成员，碧莲盛植发技术培训中心特聘带教官，参与不剃发植发技术的研发，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。',
  number: ['110421100000795', '200342110422124197709150830']
},
{
  numberIndex: '11',
  title: '伍盛为',
  en: 'WU',
  path: 'Technical',
  type: '2',
  position: '副主任医师',
  text: [
    '医疗美容主诊医师',
    '碧莲盛宁波院部技术院长',
    '碧莲盛医疗技术带头人',
    '碧莲盛植发技术培训中心特聘带教官',
    '湖南省人民医院整形美容外科进修学习',
    '参与不剃发植发技术的核心研发',
    '擅长艺术种植、多维加密及不剃发植发'
  ],
  detail: '碧莲盛医疗技术带头人，碧莲盛宁波院部技术院长，临床医学专业本科毕业，曾在湖南省人民医院整形美容外科进修学习，现为整形外科副主任医师，医疗美容主诊医师，碧莲盛植发技术培训中心特聘带教官，曾参与不剃发植发技术的核心研发。拥有近20年毛发移植临床经验，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。',
  number: ['110330300003123', '200743110432322197909235759']
},
{
  numberIndex: '13',
  title: '刘举利',
  en: 'LIU',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '碧莲盛合肥院部技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '参与不剃发植发技术的研发',
    '参与精密即插即种植发技术的升级研发',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长艺术种植、多维加密及不剃发植发'
  ],
  detail: '主治医师，碧莲盛合肥院部技术院长，碧莲盛植发技术培训中心特聘带教官，参与不剃发植发技术的研发，参与精密即插即种植发技术的升级研发。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，多次受邀参加国内毛发移植技术交流论坛，擅长艺术种植、多维加密及不剃发植发。',
  number: ['110341521000720', '201134110342422198806065599']
},
{
  numberIndex: '15',
  title: '张新进',
  en: 'ZHANG',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '碧莲盛武汉院部技术院长',
    '碧莲盛医疗技术带头人',
    '碧莲盛植发技术培训中心特聘带教官',
    '参与不剃发植发技术的研发',
    '参与不剃发植发技术的器械升级研发',
    '擅长大面积种植、艺术种植和不剃发植发'
  ],
  detail: '主治医师，碧莲盛武汉院部技术院长，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发，擅长大面积种植、艺术种植和不剃发植发，在脱发预防养护和植发技术改进领域有独到见解。',
  number: ['110110113002481', '200642110420984197902229012']
},
{
  numberIndex: '16',
  title: '韩伟',
  en: 'HAN',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '植发从业10年以上',
    '碧莲盛兰州院部技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '参与不剃发植发技术的研发',
    '参与精密即插即种植发技术的升级研发',
    '倡导大面积种植精细化操作',
    '擅长艺术种植、多维加密及不剃发植发'
  ],
  healthImg: '/team/Famous/certificate/%E9%9F%A9%E4%BC%9F/3.png',
  healthNum: '622421198708225713',
  detail: '韩伟主治医师，碧莲盛兰州院部技术院长，碧莲盛植发技术培训中心特聘带教官，参加不剃发植发技术的研发，参与精密即插即种植发技术的升级研发。从事毛发移植工作多年，累计了丰富的医学理论基础和临床实践经验，倡导大面积种植精细化操作，擅长艺术种植，多维加密及不剃发植发。',
  number: ['110650000009178', '201265110622421198708225713']
},
{
  numberIndex: '18',
  title: '陆梅',
  en: 'LU',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '碧莲盛南京院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '碧莲盛植发养护体系制定者之一',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '主治医师，碧莲盛南京院部技术院长，碧莲盛植发技术研究中心核心成员，倡导大面积种植精细化操作，碧莲盛植发养护体系制定者之一，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。',
  number: ['110341623000576', '201534110341227197502029586']
},
{
  numberIndex: '19',
  title: '赵亮',
  en: 'ZHAO',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '深圳莲空间技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '参与新式毛囊提取设备的研发',
    '擅长艺术种植、多维加密及不剃发植发'
  ],
  detail: '深圳莲空间技术院长，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与新式毛囊提取设备的研发。擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发，深受发友信赖。',
  number: ['2015098311000762', '201442110429004198912161914']
},
{
  numberIndex: '20',
  title: '陈高扬',
  en: 'CHEN',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '碧莲盛贵阳院部技术院长',
    '原安顺市人民医院医生',
    '碧莲盛植发技术研究中心核心成员',
    '参与新式毛囊提取设备的研发',
    '参与碧莲盛毛发康养体系的建设',
    '擅长艺术种植、多维加密及不剃发植发'
  ],
  detail: '主治医师，碧莲盛贵阳院部技术院长，临床医学专业本科毕业，原安顺市人民医院医生，曾在贵州医科大学附属医院进修学习，碧莲盛植发技术研究中心核心成员，参与新式毛囊提取设备的研发，参与碧莲盛毛发康养体系的建设，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。',
  number: ['110520400000663', '201452110522128198910151054']
},
{
  numberIndex: '21',
  en: 'ZHANG',
  path: 'Technical',
  type: '2',
  title: '张小雷',
  position: '主治医师',
  text: [
    '碧莲盛苏州院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与研发精密即插即种植发技术',
    '参与碧莲盛毛发康养体系的建设',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '主治医师，碧莲盛苏州院部技术院长，毕业于南昌大学临床医学专业，碧莲盛植发技术研究中心核心成员，参与研发精密即插即种植发技术，参与碧莲盛毛发康养体系的建设。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，多次受邀参加国内毛发移植技术交流论坛，擅长大面积植发、艺术种植和不剃发植发。',
  number: ['110420881001380', '201131110220182198601065713']
},
{
  numberIndex: '22',
  title: '郭永芳',
  en: 'GUO',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '碧莲盛植发技术研究中心核心成员',
    '参与研发精密即插即种植发技术',
    '参与碧莲盛毛发康养体系的建设',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '主治医师，碧莲盛石家庄院部技术院长，碧莲盛植发技术研究中心核心成员，参与研发精密即插即种植发技术，参与碧莲盛毛发康养体系的建设。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，多次受邀参加国内毛发移植技术交流论坛，擅长大面积植发、艺术种植和不剃发植发。',
  number: ['110130481000385', '200913110130481198301010911']
},
{
  numberIndex: '25',
  title: '杨云',
  en: 'YANG',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '美容主诊医师',
    '碧莲盛绵阳院部技术院长',
    '四川大学本科毕业',
    '原成都大学附属医院医生',
    '四川省美容整形协会理事',
    '碧莲盛植发技术研究中心核心成员',
    '倡导大面积种植精细化操作',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '美容主诊医师，整形外科主治医师，碧莲盛绵阳院部技术院长，四川省美容整形协会理事，四川大学本科毕业，曾在成都大学附属医院、成都新华人民医院、成都五块石医院等公立医院工作。碧莲盛植发技术研究中心核心成员，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。',
  number: ['110510108002468', '200551110511181197904242130']
},
{
  numberIndex: '26',
  title: '马昌盛',
  en: 'MA',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  text: [
    '美容主诊医师',
    '碧莲盛西宁院部技术院长',
    '河南省人民医院整形外科进修学习',
    '碧莲盛植发技术研究中心核心成员',
    '参与碧莲盛毛发康养体系的建设',
    '参与不剃发植发技术的器械改进',
    '倡导将植发技术与艺术美学结合实施',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '主治医师，美容主诊医师，碧莲盛西宁院部技术院长，原新乡市第二人民医院医生，曾在河南省人民医院整形外科进修学习，碧莲盛植发技术研究中心核心成员，参与碧莲盛毛发康养体系的建设，参与不剃发植发技术的器械改进。多年外科临床经验，多次受邀参加国内毛发移植技术交流论坛，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。',
  number: ['110410000087534', '201341110410928198311282710']
},
{
  numberIndex: '28',
  title: '鲁少康',
  en: 'LU',
  path: 'Technical',
  type: '2',
  position: '执业医师',
  text: [
    '碧莲盛银川院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '碧莲盛养固体系制定参与者之一',
    '参与不剃发植发技术的器械升级研发',
    '倡导大面积种植精细化操作',
    '擅长艺术种植、多维加密和不剃发植发'
  ],
  detail: '碧莲盛银川院部技术院长，碧莲盛植发技术研究中心核心成员，碧莲盛养固体系制定参与者之一，参与不剃发植发技术的器械升级研发，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密和不剃发植发。',
  number: ['110110108008886', '201764110640102198610160313']
},

{
  numberIndex: '30',
  en: 'YI',
  path: 'Technical',
  type: '2',
  title: '尹卓维',
  position: '执业医师',
  text: [
    '碧莲盛东莞院部技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '碧莲盛植养固体系制定者之一',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长艺术种植、多维加密及不剃发植发'
  ],
  detail: '碧莲盛东莞院部技术院长，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究院核心成员，碧莲盛植养固体系制定者之一，拥有十多年外科临床经验，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发，深受发友信赖。',
  number: ['210430981000254', '201965110430981198210261115']
},

{
  numberIndex: '34',
  en: 'DAI',
  path: 'Technical',
  type: '2',
  title: '戴尚进',
  position: '执业医师',
  text: [
    '碧莲盛佛山院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的核心研发',
    '参与毛发提取器械改进研发',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长艺术种植、大面积种植和不剃发植发'
  ],
  detail: '碧莲盛佛山院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的核心研发，参与毛发提取器械改进研发。植发手术经验丰富，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、大面积种植和不剃发植发，在治脱方案选择及术后指导方面有独到心得。',
  number: ['210430626001007', '20194311043062619911016213X']
},
{
  numberIndex: '36',
  title: '李晓龙',
  en: 'LI',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/64.png',
  src2: '/team/Transplanter/introduce/64.png',
  text: [
    '美容外科主诊医师',
    '碧莲盛呼和浩特院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的核心研发',
    '倡导植养固体系与植发手术紧密结合',
    '擅长大面积种植、艺术种植及不剃发植发'
  ],
  detail: '主治医师，美容外科主诊医师，碧莲盛呼和浩特分院技术院长，碧莲盛植发技术研究中心核心成员。他手术严格要求，严格把控医疗安全，细心耐心，深入了解患者需求，确定合理的个性化植发方案，手术专业、专注、专心，操作规范严谨，手术细节把控严格，照顾患者情绪，关心患者感受，让患者安心放心。',
  number: ['110150102001222', '201515110152827198704033316']
},
{
  numberIndex: '37',
  title: '李冬丽',
  en: 'LI',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Technical/list/100.png',
  src2: '/team/Technical/introduce/100.png',
  text: [
    '碧莲盛南宁分院技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '参与不剃发植发技术的器械升级研发',
    '10余年临床执业经验',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '碧莲盛南宁分院技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发，10余年临床执业经验，毛囊提取速度平稳，损伤率低，密度分布符合设计要求，后枕部均匀度美观度把控好，角度方向正确，符合原生发形态，自然美观度佳。',
  number: ['210450100001752', '201545210450981198911111746']
},
{
  numberIndex: '38',
  title: '邱红杰',
  en: 'QIU',
  path: 'Technical',
  type: '2',
  position: '医师',
  src: '/team/Transplanter/list/61.png',
  src2: '/team/Transplanter/introduce/61.png',
  text: [
    '碧莲盛天津分院技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '倡导大面积种植精细化操作',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '碧莲盛天津分院技术院长，碧莲盛植发技术研究中心成员，参与不剃发植发技术的研发，拥有丰富的毛发移植从业经验，倡导大面积种植精细化操作，他手术细心、耐心，毛囊提取速度快且损伤率低，在确保医疗安全的基础上不断提高医疗质量，安抚患者紧张情绪，时刻关注患者的感受，让患者安心、舒适。',
  number: ['210130502000622', '201813110130535198609243110']
},
{
  numberIndex: '39',
  title: '史后彬',
  en: 'SHI',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/29.png',
  src2: '/team/Transplanter/introduce/29.png',
  text: [
    '碧莲盛济南院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '曾在济南军区总医院进修',
    '参与不剃发植发技术的研发',
    '参与不剃发植发技术的器械改进',
    '擅长大面积种植、发际线修复和不剃发植发'
  ],
  detail: '主治医师，碧莲盛济南院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械改进。曾在济南军区总医院进修，拥有丰富的毛发移植从业经验，可根据发友面部轮廓及要求结合美学设计给予发友满意设计方案，手术细心耐心，术中关心患者感受，严格把控医疗安全医疗质量。',
  number: ['210370923000628', '201837110370923199006180611']
},
{
  numberIndex: '40',
  title: '伍湘涛',
  en: 'WU',
  path: 'Technical',
  type: '2',
  position: '医师',
  src: '/team/Transplanter/list/36.png',
  src2: '/team/Transplanter/introduce/36.png',
  text: [
    '碧莲盛海口院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与碧莲盛毛发康养体系的建设',
    '参与不剃发植发技术的升级研发',
    '倡导植养固体系与植发手术紧密结合',
    '擅长多维加密、艺术种植和不剃发植发'
  ],
  detail: '碧莲盛海口分院技术院长，碧莲盛植发技术研究中心核心成员，参与碧莲盛毛发康养体系的建设，参与不剃发植发技术的升级研发，拥有23年临床执业经验，倡导植养固体系与植发手术紧密结合，在后枕部均匀提取方面有独到心得，擅长多维加密、艺术种植和不剃发植发。',
  number: ['110460100006582', '201046110432322197406147933']
},
{
  numberIndex: '41',
  title: '于艳',
  en: 'YU',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/7.png',
  src2: '/team/Transplanter/introduce/7.png',
  text: [
    '美容外科主诊医师',
    '碧莲盛北京院部技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的核心研发',
    '擅长不剃发植发、加密种植及大面积种植'
  ],
  detail: '美容外科主诊医师，碧莲盛北京院部技术院长，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的核心研发。植发从业近20年，植发手术经验丰富，对植发技术精益求精，毛囊提取损伤率低，严格把控医疗安全，提高医疗质量，把患者的利益放在第一位。',
  number: ['110120104000508', '2001131101329027305200624']
},
{
  numberIndex: '42',
  title: '张章',
  en: 'ZHANG',
  path: 'Technical',
  type: '2',
  src: '/team/Transplanter/list/2.png',
  src2: '/team/Transplanter/introduce/2.png',
  position: '主治医师',
  text: [
    '碧莲盛沈阳院部技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的核心研发',
    '多次受邀参加国内毛发移植技术交流论坛',
    '沈阳院部连续5年毛发移植临床执业',
    '擅长艺术种植、大面积种植及不剃发植发'
  ],
  detail: '碧莲盛沈阳院部技术院长，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的核心研发，多次受邀参加国内毛发移植技术交流论坛，在沈阳院部拥有5年毛发移植临床经验，擅长艺术种植、大面积种植及不剃发植发。',
  number: ['110230200005032', '201423110230204198812192112']
},
{
  numberIndex: '43',
  title: '吕晓明',
  en: 'LV',
  path: 'Technical',
  type: '2',
  src: '/team/Transplanter/list/23.png',
  src2: '/team/Transplanter/introduce/23.png',
  position: '主治医师',
  text: [
    '美容外科主诊医师',
    '碧莲盛大连院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的核心研发',
    '参与碧莲盛毛发康养体系的建设',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '整形外科主治医师，美容外科主诊医师，碧莲盛大连院部技术院长，碧莲盛植发技术研究中心核心成员，拥有10余年毛发移植从业经验，参与不剃发植发技术的核心研发，参与碧莲盛毛发康养体系的建设，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案。',
  number: ['110210681000801', '201421110210281198811266844']
},
//   {
//     numberIndex: "44",
//     title: '刘禹亮',
//     en: 'LIU',
//     path: 'Technical',
//     type: '2',
//     position: '主治医师',
//     text: [
//       '临床医学硕士学历',
//       '美容外科主诊医师',
//       '碧莲盛哈尔滨院部技术院长',
//       '碧莲盛植发技术培训中心特聘带教官',
//       '头部美学研究院头部美学医师',
//       '碧莲盛植发技术研究中心核心成员',
//       '擅长大面积植发、艺术种植和不剃发植发',
//     ],
//     detail: '医学硕士，美容外科主诊医师，碧莲盛哈尔滨院部技术院长、医疗负责人，碧莲盛植发技术培训中心特聘带教官，头部美学研究院头部美学医师。他专业知识强，手术专注、细心、耐心，毛囊提取快稳准，损伤率低，后枕部均匀度美观把控好，操作规范严谨，细节把控严格。',
//     number: ['110230000035390', '/']
//   },
//   {
//     numberIndex: "44",
//     title: '宋爱聪',
//     en: 'SONG',
//     path: 'Technical',
//     type: '2',
//     position: '医师',
//     text: [
//       '碧莲盛长春院部技术院长',
//       '碧莲盛植发技术研究中心核心成员',
//       '参与不剃发植发技术的研发',
//       '参与不剃发植发技术的器械改进',
//       '倡导将植发技术与艺术美学结合实施',
//       '擅长艺术种植、多维加密和不剃发植发',
//     ],
//     detail: '碧莲盛长春院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械改进。多年外科临床经验，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密和不剃发植发，倡导将植发技术与艺术美学结合实施。',
//     number: ['210130400004034', '202111110130423198712021734']
//   },
{
  numberIndex: '45',
  title: '白腾飞',
  en: 'BAI',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/70.png',
  src2: '/team/Transplanter/introduce/70.png',
  text: [
    '美容外科主诊医师',
    '碧莲盛昆明院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '参与不剃发植发技术的器械升级研发',
    '多次受邀参加参加国内毛发移植技术交流论坛',
    '擅长艺术种植、多维加密和不剃发植发'
  ],
  detail: '美容外科主诊医师，碧莲盛昆明院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发。多年外科临床经验，多次受邀参加国内毛发移植技术交流论坛，擅长艺术种植、多维加密和不剃发植发，倡导将植发技术与艺术美学结合实施。',
  number: ['110530426000116', '200850110513523197809163397']
},

{
  numberIndex: '46',
  title: '强志顺',
  en: 'QIANG',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/28.png',
  src2: '/team/Transplanter/introduce/28.png',
  text: [
    '碧莲盛西安院部技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '西安院部连续6年毛发移植临床执业',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '高年资主治医师，碧莲盛西安院部技术院长，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，在西安院部拥有6年毛发移植临床经验。他倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案擅长大面积植发、艺术种植和不剃发植发。',
  number: ['110610500001415', '201261110642222198410180830']
},
//   {
//     numberIndex: "47",
//     title: '刘超',
//     en: 'LIU',
//     path: 'Technical',
//     type: '2',
//     position: '主治医师',
//     text: [
//       '美容外科主诊医师',
//       '碧莲盛重庆院部技术院长',
//       '碧莲盛植发技术研究中心核心成员',
//       '参与不剃发植发技术的升级研发',
//       '拥有8年毛发移植从业经验',
//       '擅长大面积植发、艺术种植和不剃发植发',
//     ],
//     detail: '美容外科主诊医师，碧莲盛重庆院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的升级研发，拥有8年毛发移植从业经验。他经验丰富，工作认真，服务至上，责任心强，严格把控医疗质量，服务获得患者的一致好评。',
//     number: ['110440300024580', '/']
//   },
{
  numberIndex: '48',
  title: '徐乐',
  en: 'XU',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/49.png',
  src2: '/team/Transplanter/introduce/49.png',
  text: [
    '郑州大学外科学医学硕士',
    '碧莲盛成都院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '参与碧莲盛毛发康养体系的建设',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '整形外科主治医师，外科学医学硕士，碧莲盛成都院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与碧莲盛毛发康养体系的建设，工作责任心强，认真细致，重视医疗安全，严格把医疗质量放第一位，拥有丰富的毛发移植从业经验，多次受邀参加国内毛发移植技术交流论坛。',
  number: ['110410100016877', '201841110420683199101291250']
},
{
  numberIndex: '49',
  title: '赵生智',
  en: 'ZHAO',
  path: 'Technical',
  type: '2',
  position: '医师',
  src: '/team/Transplanter/list/62.png',
  src2: '/team/Transplanter/introduce/62.png',
  text: [
    '碧莲盛乌鲁木齐院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的升级研发',
    '参与不剃发植发技术的器械升级研发',
    '倡导植养固体系与植发手术紧密结合',
    '擅长大面积种植、艺术种植及不剃发植发'
  ],
  detail: '碧莲盛乌鲁木齐院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的升级研发，参与不剃发植发技术的器械升级研发，拥有8年临床执业经验，倡导植养固体系与植发手术紧密结合，擅长大面积种植、艺术种植及不剃发植发，在治脱方案选择及术后指导方面有独到心得。',
  number: ['210620602000212', '201662210622301198208062654']
},
{
  numberIndex: '50',
  title: '贺俊',
  en: 'HE',
  path: 'Technical',
  type: '2',
  position: '医师',
  src: '/team/Transplanter/list/31.png',
  src2: '/team/Transplanter/introduce/31.png',
  text: [
    '美容外科主诊医师',
    '毕业于南华大学临床医学专业',
    '碧莲盛深圳院部技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '倡导大面积种植精细化操作',
    '擅长量身定制个性化植发方案、发际线修复和艺术种植'
  ],
  detail: '美容外科主诊医师，碧莲盛深圳院部技术院长，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，毕业于南华大学临床医学专业，倡导大面积种植精细化操作，拥有丰富的毛发移植从业经验，严格把控医疗安全，提高医疗质量，关心患者的感受，让患者安心放心。',
  number: ['110430481000910', '201543110430419198009050572']
},
{
  numberIndex: '51',
  title: '张卓',
  en: 'ZHANG',
  path: 'Technical',
  type: '2',
  position: '医师',
  src: '/team/Transplanter/list/15.png',
  src2: '/team/Transplanter/introduce/15.png',
  text: [
    '美容外科主诊医师',
    '碧莲盛名医堂核心成员',
    '碧莲盛杭州院部技术院长',
    '碧莲盛集团研发部成员',
    '碧莲盛医疗技术带头人',
    '碧莲盛植发技术培训中心特聘带教官',
    '擅长艺术种植、发际线修复及不剃发植发'
  ],
  detail: '美容外科主诊医师，碧莲盛杭州院部技术院长，碧莲盛名医堂核心成员，碧莲盛集团研发部成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，拥有8年毛发移植从业经验。他毛囊提取精准，后枕均匀美观，责任心强，严格把医疗质量放第一位，关心每一位发友的感受，带领团队用精湛的技术，优质服务对待每一位患者。',
  number: ['110110108008293', '201542110420881198910173354']
},
{
  numberIndex: '52',
  title: '马发林',
  en: 'MA',
  path: 'Technical',
  type: '2',
  position: '医师',
  src: '/team/Transplanter/list/60.png',
  src2: '/team/Transplanter/introduce/60.png',
  text: [
    '碧莲盛厦门院部技术院长',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '参与不剃发植发技术的器械升级研发',
    '多次受邀参加参加国内毛发移植技术交流论坛',
    '擅长艺术种植、多维加密和不剃发植发'
  ],
  detail: '碧莲盛厦门院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发，擅长根据脱发者的具体情况量身打造植发方案，多次受邀参加参加国内毛发移植技术交流论坛，擅长艺术种植、多维加密和不剃发植发。',
  number: ['210420881001197', '202042110420881199311012932']
},
{
  numberIndex: '52',
  title: '罗道龙',
  en: 'LUO',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/43.png',
  src2: '/team/Transplanter/introduce/43.png',
  text: [
    '碧莲盛泉州院部技术院长',
    '碧莲盛医疗技术带头人',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '多次受邀参加国内毛发移植技术交流论坛',
    '擅长大面积植发、艺术种植和不剃发植发'
  ],
  detail: '碧莲盛泉州院部技术院长，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，多次受邀参加国内毛发移植技术交流论坛，拥有14年临床执业经验，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。',
  number: ['110440112001071', '200942110429005198208302054']
},
{
  numberIndex: '53',
  title: '罗继森',
  en: 'LUO',
  path: 'Technical',
  type: '2',
  position: '医师',
  src: '/team/Transplanter/list/42.png',
  src2: '/team/Transplanter/introduce/42.png',
  text: [
    '碧莲盛青岛院部技术院长',
    '毕业于华中科技大学',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '参与毛发提取器械改进研发',
    '倡导大面积种植精细化操作',
    '擅长艺术种植、多维加密及不剃发植发'
  ],
  detail: '毕业于华中科技大学，碧莲盛青岛院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与毛发提取器械改进研发，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，操作规范严谨，细节把控严格，时刻关注并提升患者的舒适体验。',
  number: ['210420600001401', '201942110372929199106093013']
},
{
  numberIndex: '54',
  title: '陈燕华',
  en: 'CHEN',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/14.png',
  src2: '/team/Transplanter/introduce/14.png',
  text: [
    '美容外科主诊医师',
    '碧莲盛名医堂核心成员',
    '碧莲盛上海院部技术院长',
    '碧莲盛植发技术培训中心特聘带教官',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的核心研发',
    '擅长大面积种植、艺术种植和不剃发植发'
  ],
  detail: '美容外科主诊医师，碧莲盛上海院部技术院长，碧莲盛名医堂核心成员，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的核心研发，参与不剃发植发技术的器械改进，严格把医疗质量放第一位，关心每一位发友的感受，带领团队用精湛的技术，优质服务对待每一位患者。',
  number: ['110140106000764', '201214110142603198504099317']
},
{
  numberIndex: '55',
  title: '李世涛',
  en: 'LI',
  path: 'Technical',
  type: '2',
  position: '主治医师',
  src: '/team/Transplanter/list/30.png',
  src2: '/team/Transplanter/introduce/30.png',
  text: [
    '碧莲盛郑州院部技术院长',
    '河南科技大学临床医学专业毕业',
    '碧莲盛植发技术研究中心核心成员',
    '参与不剃发植发技术的研发',
    '参与碧莲盛毛发康养体系的建设',
    '擅长个性化定制方案、艺术种植和不剃发植发'
  ],
  detail: '碧莲盛郑州院部技术院长，河南科技大学临床医学专业毕业，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与碧莲盛毛发康养体系的建设，拥有10年临床执业经验，倡导大面积种植精细化操作，严格把控医疗安全，提高医疗质量，关心患者的感受，让患者安心放心。',
  number: ['110411729000104', '201341110410724198206016019']
}
]
